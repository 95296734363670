import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store'
import type { JobQueue } from 'src/types/notification';
import { AXIOS_DELETE, AXIOS_GET, AXIOS_PATCH, AXIOS_POST } from './service/apiService';
import {toastr} from 'react-redux-toastr';

const initialState: JobQueue = {
  jobQueueList: [],
  page: 1,
  limit: 10,
  totalCount: 0,
  formConfig: null,
  selectedJobValue: null,
  jobId: null,
  loader: false,
  deleteRecId: null
};

const slice = createSlice({
  name: 'jobQueue',
  initialState,
  reducers: {
    getJobList(state: JobQueue, action: PayloadAction<{ jobData: any[], totalCount: number, formConfig: any, jobId: number }>) {
      state.jobQueueList = action.payload.jobData;
      state.totalCount = action.payload.totalCount;
      state.formConfig = action.payload.formConfig;
      state.jobId = action.payload.jobId;
      state.totalCount = action.payload.totalCount;
    },
    getJobData(state: JobQueue, action: PayloadAction<{ jobData: any, totalCount: number, formConfig: any, jobId: number }>) {
      state.selectedJobValue = action.payload.jobData;
      state.formConfig = action.payload.formConfig;
      state.jobId = action.payload.jobId;
      state.totalCount = action.payload.totalCount;
    },
    getJobValueData(state: JobQueue, action: PayloadAction<{ jobData: any, totalCount: number, jobId: number }>) {
      state.selectedJobValue = action.payload.jobData;
      state.jobId = action.payload.jobId;
      state.totalCount = action.payload.totalCount;
    },
    loader(state: JobQueue, action: PayloadAction<any>) {
      state.loader = action.payload.loader
    },
    setDeleteRecordId(state: JobQueue, action: PayloadAction<{ deleteRecId: string | number }>) {
      state.deleteRecId = action.payload.deleteRecId
    },
    deleteListRecordFromStateById(state: JobQueue, action: PayloadAction<{ jobQueueList: any[], deleteRecId: string | number }>) {
      let tempList: any[] = action.payload.jobQueueList || [];
      let finalPayloadList: any[] = tempList.length ? tempList.filter(item => item?.id != action.payload.deleteRecId) : [...tempList];
      state.jobQueueList = finalPayloadList;
      state.totalCount = finalPayloadList.length;  
  }
  }
});

export const reducer = slice.reducer;

export const getJobQueue = (page: number = 1, limit: number = 5, searchKey: string = ''): AppThunk => async (dispatch) => {
  dispatch(slice.actions.loader({ loader: true }));
  const response: any = await AXIOS_GET(`/api/jobqueueValue/1?page=${page + 1}&limit=${limit}&searchKey=${searchKey}`, {}, {});
  dispatch(slice.actions.getJobList({ jobData: response?.data?.queue || [], totalCount: response?.data?.totalCount || 0, formConfig: response?.data?.jobQueueForm || null, jobId: response?.data?.job || null }));
  dispatch(slice.actions.loader({ loader: false }));

};

export const getJobQueueById = (id): AppThunk => async (dispatch) => {
  const response: any = await AXIOS_GET('/api/jobqueueValue/find/' + id, {}, {});

  if (response) {
    dispatch(slice.actions.getJobData({ 
      jobData: response?.data?.jobValue || [], 
      formConfig: response?.data?.formConfig?.formPayload || null, 
      jobId: response?.data?.job || null, 
      totalCount: response?.data?.totalCount || 0 
    }));
  } else {

  }
};


export const updateJobQueue = (id, payload): AppThunk => async (dispatch) => {
  try {
    dispatch(slice.actions.loader({ loader: true }));
    const response: any = await AXIOS_PATCH('/api/jobqueueValue/' + id, payload, {});

    dispatch(slice.actions.getJobValueData({ 
      jobData: response?.data || [], 
      jobId: response?.data?.job || null, 
      totalCount: response?.data?.totalCount || 0 
    }));

  } catch(err) {
    toastr.error('Error', err);

  } finally {
    dispatch(slice.actions.loader({ loader: false }));
  }

};

export const saveJobQueue = (payload): AppThunk => async (dispatch) => {
  try {
    dispatch(slice.actions.loader({ loader: true }));
    const response: any = await AXIOS_POST('/api/jobqueueValue', payload, {});

    if (response?.status) {
      toastr.success('Success', response?.message);
      dispatch(slice.actions.getJobValueData({ jobData: response?.jobValue || [], jobId: response.job || null, totalCount: response?.totalCount || 0 }));
    } else {
      toastr.error('Error', response?.message);
    }
  } catch(err) {
    toastr.error('Error', err);
  } finally {
    dispatch(slice.actions.loader({ loader: false }));
  }

};

export const getJobById = (id): AppThunk => async (dispatch) => {
  try {
    const response: any = await AXIOS_GET('/api/jobqueue/' + id, {}, {});

    if (response) {
      dispatch(slice.actions.getJobData({ jobData: null, formConfig: response?.data?.formPayload || null, jobId: response.data?.job || null, totalCount: response.data?.totalCount || 0 }));
    }
  } catch(err) {

  } finally {
    dispatch(slice.actions.loader({ loader: false }));
  }

};

export const updateJobQueueData = (id, payload): AppThunk => async (dispatch) => {
  const response: any = await AXIOS_PATCH('/api/jobqueue/' + id, payload, {});

  if (response?.status) {
    toastr.success('Success', response?.message);

    dispatch(slice.actions.getJobData({ 
      jobData: response?.data?.jobValue || [], 
      formConfig: response?.data?.formPayload || null, 
      jobId: response.data?.job || null, 
      totalCount: response.data?.totalCount || 0 
    }));
  } else {
    toastr.error('Error', response?.message);
  }
};

export const deleteJobQueueValueById = (jobQueueList, id): AppThunk => async (dispatch) => {
  const response: any = await AXIOS_DELETE('/api/jobqueueValue/' + id, {});
  dispatch(slice.actions.setDeleteRecordId({ deleteRecId: null }));

  if (response?.status) {
      toastr.success('Success', response?.message);
      // dispatch(slice.actions.deleteListRecordFromStateById({ jobQueueList: jobQueueList, deleteRecId: id }));
  } else {
      toastr.error('Error', response?.message);
  }

  return response?.status == true ? true : false;
};

/* #region custom functions */
export const setDeleteRecordId = (id) => (dispatch) => {
  dispatch(slice.actions.setDeleteRecordId({ deleteRecId: id ? id : null }));
}
/* #endregion custom functions */

export default slice;
