import { createSlice } from '@reduxjs/toolkit';
import { PayloadAction, current } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store'
import { AXIOS_DELETE, AXIOS_GET, AXIOS_PATCH, AXIOS_POST } from './service/apiService';
import { toastr } from 'react-redux-toastr';
import { KanbanTaskStateData } from 'src/types/kanbanTask';

const initialState: KanbanTaskStateData = {
    taskCategoryList: [],
    categorizedTaskDetails: {},
    selectedTaskObject: null,
    page: 1,
    limit: 5,
    totalCount: 0,
    loader: false,
    deleteRecId: null
};

const slice = createSlice({
    name: 'kanbanTask',
    initialState,
    reducers: {
        setTaskCategoryList(state: KanbanTaskStateData, action: PayloadAction<{ taskCategoryList: any[], totalCount: number }>) {
            state.taskCategoryList = action.payload.taskCategoryList;
            state.totalCount = action.payload.totalCount;
        },
        setCategorizedTaskDetails(state: KanbanTaskStateData, action: PayloadAction<{ categoryId: string | number, taskList: any[], totalCount: number }>) {
            const { categoryId } = action.payload;
            let categorizedTaskDetails: any = { ...current(state).categorizedTaskDetails };
            let taskCatDetail = {...categorizedTaskDetails[categoryId]};
            let taskList = taskCatDetail?.taskList || [];
            taskList = [...taskList, ...action.payload.taskList];
            taskCatDetail.taskList = [...taskList]
            taskCatDetail.totalCount = action.payload.totalCount;
            categorizedTaskDetails[categoryId] = { ...taskCatDetail };
            state.categorizedTaskDetails = { ...categorizedTaskDetails };
        },
        loader(state: KanbanTaskStateData, action: PayloadAction<any>) {
            state.loader = action.payload.loader
        },
        setDeleteRecordId(state: KanbanTaskStateData, action: PayloadAction<{ deleteRecId: string | number }>) {
            state.deleteRecId = action.payload.deleteRecId
        },
        moveTask(state: KanbanTaskStateData, action: PayloadAction<{ taskId: string | number, categoryId: string | number, task: any }>) {
            state.categorizedTaskDetails = action.payload.task
        },
        addTasks(state: KanbanTaskStateData, action: PayloadAction<{ categoryId: string | number, task: any }>) {
            let addTasksD = { ...current(state).categorizedTaskDetails }
            const toCategory = { ...addTasksD[Number(action.payload.categoryId)] }

            toCategory.taskList = [...toCategory.taskList, action.payload.task]
            addTasksD[Number(action.payload.categoryId)] = toCategory
            state.categorizedTaskDetails = addTasksD;
        },
        updateTask(state: KanbanTaskStateData, action: PayloadAction<{ categoryId: string | number, taskObject: any, taskId: string | number }>) {
            let categorizedTaskDetails: any = { ...current(state).categorizedTaskDetails };
            let toCategory = { ...categorizedTaskDetails[Number(action.payload.categoryId)] };
            let tempTaskList: any[] = toCategory?.taskList || [];

            if (tempTaskList.length) {
                let ntasks = [];

                for (const iterator of tempTaskList) {
                    let ntaskI = { ...iterator }
                    if (ntaskI?.id == action.payload.taskId) {
                        ntaskI = { ...action.payload.taskObject };
                    }

                    ntasks.push(ntaskI);
                }

                toCategory.taskList = [...ntasks]
                categorizedTaskDetails[Number(action.payload.categoryId)] = toCategory;
                state.categorizedTaskDetails = categorizedTaskDetails;
            }
        },
        setTaskSingleData(state: KanbanTaskStateData, action: PayloadAction<{ selectedTaskObject: any }>) {
            state.selectedTaskObject = action.payload.selectedTaskObject
        },
        resetTaskListOnDelete(state: KanbanTaskStateData, action: PayloadAction<{ categoryId: string | number, taskId: string | number }>) {
            let categorizedTaskDetails: any = { ...current(state).categorizedTaskDetails };
            let toCategory = { ...categorizedTaskDetails[Number(action.payload.categoryId)] };
            let tempTaskList: any[] = toCategory?.taskList || [];

            if (tempTaskList.length) {
                let ntasks = [];
                for (const iterator of tempTaskList) {
                    let ntaskI = { ...iterator }
                    if (ntaskI?.id != action.payload.taskId) {
                        ntasks.push(ntaskI);
                    }
                }

                toCategory.taskList = [...ntasks]
                categorizedTaskDetails[Number(action.payload.categoryId)] = toCategory;
                state.categorizedTaskDetails = categorizedTaskDetails;
            }
        },
        addNewCategory(state: KanbanTaskStateData, action: PayloadAction<{ categoryObject: any }>) {
            let allCategoryList: any[] = [...current(state).taskCategoryList];
            let tempTotalCount: number = current(state).totalCount;
            let tempCategoryObject: any = { ...action.payload.categoryObject };
            let tempFinalArray: any[] = [...allCategoryList, tempCategoryObject];
            state.taskCategoryList = [...tempFinalArray];
            state.totalCount = tempTotalCount + 1;
        },
        updateOldCategory(state: KanbanTaskStateData, action: PayloadAction<{ categoryId: string | number, categoryObject: any }>) {
            let allCategoryList: any[] = [...current(state).taskCategoryList];

            if (allCategoryList.length) {
                let ntasks = [];

                for (const iterator of allCategoryList) {
                    let ntaskI = { ...iterator }
                    if (ntaskI?.id == action.payload.categoryId) {
                        ntaskI = { ...action.payload.categoryObject };
                    }

                    ntasks.push(ntaskI);
                }

                state.taskCategoryList = ntasks;
            }
        },
        resetCategoryListOnDelete(state: KanbanTaskStateData, action: PayloadAction<{ categoryId: string | number }>) {
            let allCategoryList: any[] = [...current(state).taskCategoryList];
            let tempTotalCount: number = current(state).totalCount;

            if (allCategoryList.length) {
                let ntasks = [];
                for (const iterator of allCategoryList) {
                    let ntaskI = { ...iterator }
                    if (ntaskI?.id != action.payload.categoryId) {
                        ntasks.push(ntaskI);
                    }
                }

                state.taskCategoryList = [...ntasks];
                state.totalCount = tempTotalCount - 1;
            }
        },
        addChecklist(state: KanbanTaskStateData, action: PayloadAction<{ checklistObject: any }>) {
            let taskObject: any = { ...current(state).selectedTaskObject };
            let tempCheckListObject = { ...action.payload.checklistObject };
            let nChecklist = [...taskObject?.checklist];
            nChecklist.push(tempCheckListObject);
            taskObject.checklist = [...nChecklist];
            state.selectedTaskObject = taskObject;
        },
        updateChecklist(state: KanbanTaskStateData, action: PayloadAction<{ checklistObject: any }>) {
            let taskObject: any = { ...current(state).selectedTaskObject };
            let tempCheckListObject = { ...action.payload.checklistObject };
            let stateChecklist = [...taskObject?.checklist];

            if (stateChecklist.length) {
                let ntasks = [];
                for (const iterator of stateChecklist) {
                    let ntaskI = { ...iterator };

                    if (ntaskI?.id == tempCheckListObject?.id) {
                        ntaskI = { ...action.payload.checklistObject };
                    }

                    ntasks.push(ntaskI);
                }

                taskObject.checklist = [...ntasks];
                state.selectedTaskObject = taskObject;
            }
        },
        resetChecklistOnDelete(state: KanbanTaskStateData, action: PayloadAction<{ checklistId: string | number }>) {
            let taskObject: any = { ...current(state).selectedTaskObject };
            let stateChecklist = [...taskObject?.checklist];

            if (stateChecklist.length) {
                let ntasks = [];
                for (const iterator of stateChecklist) {
                    let ntaskI = { ...iterator }

                    if (ntaskI?.id != action.payload.checklistId) {
                        ntasks.push(ntaskI);
                    }
                }

                taskObject.checklist = [...ntasks];
                state.selectedTaskObject = taskObject;
            }
        },
        addChecklistItem(state: KanbanTaskStateData, action: PayloadAction<{ checklistItemObject: any, checklistId: string | number }>) {
            let taskObject: any = { ...current(state).selectedTaskObject };
            let ipChecklistItemObject: any = { ...action.payload.checklistItemObject };
            let nChecklist = [...taskObject?.checklist];
            let checklistObject = nChecklist.filter((obj: any) => (obj.id) == action.payload.checklistId)[0];
            let finalchecklistObject = { ...checklistObject };
            let tempChecklistTask: any[] = [...checklistObject.checklistTask];
            tempChecklistTask.push(ipChecklistItemObject);
            finalchecklistObject.checklistTask = [...tempChecklistTask];

            if (nChecklist.length) {
                let ntasks = [];
                for (const iterator of nChecklist) {
                    let ntaskI = { ...iterator }

                    if (ntaskI?.id == action.payload.checklistId) {
                        ntaskI = { ...finalchecklistObject };
                    }

                    ntasks.push(ntaskI);
                }

                taskObject.checklist = [...ntasks];
                state.selectedTaskObject = taskObject;
            }
        },
        updateChecklistItem(state: KanbanTaskStateData, action: PayloadAction<{ checklistItemObject: any, checklistItemId: string | number, checklistId: string | number }>) {
            let taskObject: any = { ...current(state).selectedTaskObject };
            let stateChecklist = [...taskObject?.checklist];
            let checklistObject = stateChecklist.filter((obj: any) => (obj.id) == action.payload.checklistId)[0];
            let finalchecklistObject = { ...checklistObject };

            let tempChecklistTask: any[] = [...checklistObject.checklistTask];

            if (tempChecklistTask.length) {
                let ntasks = [];
                for (const iterator of tempChecklistTask) {
                    let ntaskI = { ...iterator }

                    if (ntaskI?.id == action.payload.checklistItemId) {
                        ntaskI = { ...action.payload.checklistItemObject };
                    }

                    ntasks.push(ntaskI);
                }

                finalchecklistObject.checklistTask = [...ntasks];
                
                let clists = [];
                for (const checkListIterator of stateChecklist) {
                    let clistI = { ...checkListIterator }
                    
                    if (clistI?.id == action.payload.checklistId) {
                        clistI = { ...finalchecklistObject };
                    }

                    clists.push(clistI);
                }

                taskObject.checklist = [...clists];
                state.selectedTaskObject = taskObject;            
            }
        },
        resetChecklistItemOnDelete(state: KanbanTaskStateData, action: PayloadAction<{ checklistId: string | number, checklistItemId: string | number }>) {
            let taskObject: any = { ...current(state).selectedTaskObject };
            let stateChecklist = [...taskObject?.checklist];
            let checklistObject = stateChecklist.filter((obj: any) => (obj.id) == action.payload.checklistId)[0];
            let finalchecklistObject = { ...checklistObject };

            let tempChecklistTask: any[] = [...checklistObject.checklistTask];

            if (tempChecklistTask.length) {
                let ntasks = [];
                for (const iterator of tempChecklistTask) {
                    let ntaskI = { ...iterator }

                    if (ntaskI?.id != action.payload.checklistItemId) {
                        ntasks.push(ntaskI);
                    }
                }

                finalchecklistObject.checklistTask = [...ntasks];
                
                let clists = [];
                for (const checkListIterator of stateChecklist) {
                    let clistI = { ...checkListIterator }
                    
                    if (clistI?.id == action.payload.checklistId) {
                        clistI = { ...finalchecklistObject };
                    }

                    clists.push(clistI);
                }

                taskObject.checklist = [...clists];
                state.selectedTaskObject = taskObject;            
            }
        }
    }
});

export const reducer = slice.reducer;

export const getAllTasksCategories = (page: number = 0, limit: number = 5, searchKey: string = ''): AppThunk => async (dispatch) => {
    dispatch(slice.actions.loader({ loader: true }));

    const response: any = await AXIOS_GET(`/api/task/category?page=${page + 1}&limit=${limit}&searchKey=${searchKey}`, {}, {});

    if (response) {
        dispatch(slice.actions.setTaskCategoryList({ taskCategoryList: response?.data || [], totalCount: response?.totalCount || 0 }))
    }

    dispatch(slice.actions.loader({ loader: false }));
};

export const getAllTasks = (categoryId: string | number = '', skip: number = 0, limit: number = 5, searchKey: string = ''): AppThunk => async (dispatch) => {
    dispatch(slice.actions.loader({ loader: true }));

    const response: any = await AXIOS_GET(`/api/task/task-by-category?skip=${skip}&limit=${limit}&searchKey=${searchKey}&taskCategory=${categoryId}`, {}, {});

    if (response) {
        dispatch(slice.actions.setCategorizedTaskDetails({ categoryId: categoryId, taskList: response?.data || [], totalCount: response?.totalCount || 0 }))
    }

    dispatch(slice.actions.loader({ loader: false }));
};

export const moveTask = (categoryId: number, taskId: number, task: any): AppThunk => async (dispatch) => {
    dispatch(slice.actions.loader({ loader: true }));
    const response: any = await AXIOS_GET(`/api/task/move-task/${taskId}/${categoryId}`, {}, {});
    if (response && response.data && response.data.moved) {
        dispatch(slice.actions.moveTask({ categoryId, taskId, task }))
    }
    else {
        toastr.warn('Error', response?.message);

    }
}

export const addCategory = (payload): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_POST('/api/task/category', payload, {});

    if (response?.data?.id) {
        toastr.success('Success', response?.message);
        dispatch(slice.actions.addNewCategory({ categoryObject: response?.data }));
    } else {
        toastr.error('Error', response?.message);
    }
};

export const updateCategory = (id, payload): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_PATCH(`/api/task/category/${id}`, payload, {});

    if (response?.data?.id) {
        toastr.success('Success', response?.message);
        dispatch(slice.actions.updateOldCategory({ categoryId: response?.data?.id || null, categoryObject: response?.data || null }))
    } else {
        toastr.error('Error', response?.message);
    }

    return response?.data?.id ? true : false;
};

export const addTasks = (payload): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_POST('/api/task/new-task', payload, {});

    if (response?.data?.id) {
        toastr.success('Success', response?.message);

        // history.replace(`/app/lead/create-lead/${response?.data?.id}`);
        dispatch(slice.actions.addTasks({ task: response?.data, categoryId: payload.taskCategory }));
    } else {
        toastr.error('Error', response?.message);
    }
};

export const updateTask = (id, payload): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_PATCH(`/api/task/new-task/${id}`, payload, {});

    if (response?.data?.id) {
        toastr.success('Success', response?.message);
        dispatch(slice.actions.updateTask({ categoryId: response?.data?.task?.id || null, taskObject: response?.data, taskId: id }))
    } else {
        toastr.error('Error', response?.message);
    }

    return response?.data?.id ? true : false;
};

export const getTaskById = (taskId): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_GET('/api/task/new-task/' + taskId, {}, {});

    dispatch(slice.actions.setTaskSingleData({ selectedTaskObject: response?.data?.id ? response?.data : null }));

    if (response?.data?.id) {

    } else {
        toastr.error('Error', response?.message);
    }

    return response?.data?.id ? true : false;
};

export const deleteTaskById = (categoryId, taskId): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_DELETE('/api/task/new-task/' + taskId, {});

    if (response?.data?.isDeleted) {
        dispatch(slice.actions.resetTaskListOnDelete({ categoryId: categoryId, taskId: taskId }));

        toastr.success('Success', response?.message);
    } else {
        toastr.error('Error', response?.message);
    }
};

export const deleteCategoryById = (categoryId): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_DELETE('/api/task/category/' + categoryId, {});

    if (response?.data?.isDeleted) {
        dispatch(slice.actions.resetCategoryListOnDelete({ categoryId: categoryId }));

        toastr.success('Success', response?.message);
    } else {
        toastr.error('Error', response?.message);
    }

    return response?.data?.isDeleted == true ? true : false;
};

export const addChecklist = (payload): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_POST('/api/task/checklist', payload, {});

    if (response?.data?.id) {
        toastr.success('Success', response?.message);
        dispatch(slice.actions.addChecklist({ checklistObject: response?.data }));
    } else {
        toastr.error('Error', response?.message);
    }
}

export const updateChecklist = (payload): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_POST('/api/task/checklist', payload, {});

    if (response?.data?.id) {
        toastr.success('Success', response?.message);
        dispatch(slice.actions.updateChecklist({ checklistObject: response?.data }));
    } else {
        toastr.error('Error', response?.message);
    }
}

export const deleteChecklistById = (checklistId): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_DELETE('/api/task/checklist/' + checklistId, {});

    if (response?.data?.isDeleted) {
        dispatch(slice.actions.resetChecklistOnDelete({ checklistId: checklistId }));

        toastr.success('Success', response?.message);
    } else {
        toastr.error('Error', response?.message);
    }

    // return response?.data?.isDeleted == true ? true : false;
};

export const addChecklistItem = (payload): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_POST('/api/task/checklisttask', payload, {});

    if (response?.data?.id) {
        toastr.success('Success', response?.message);
        dispatch(slice.actions.addChecklistItem({ checklistItemObject: response?.data, checklistId: response?.data?.checklist || null }));
    } else {
        toastr.error('Error', response?.message);
    }
}

export const updateChecklistItem = (checklistId: string | number, payload): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_POST('/api/task/checklisttask', payload, {});

    if (response?.data?.id) {
        toastr.success('Success', response?.message);
        dispatch(slice.actions.updateChecklistItem({ checklistItemObject: response?.data, checklistItemId: response?.data?.id, checklistId: checklistId }));
    } else {
        toastr.error('Error', response?.message);
    }
}

export const deleteChecklistItemById = (checklistId, checklistItemId): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_DELETE('/api/task/checklisttask/' + checklistItemId, {});

    if (response?.data?.isDeleted) {
        dispatch(slice.actions.resetChecklistItemOnDelete({ checklistId: checklistId, checklistItemId: checklistItemId }));

        toastr.success('Success', response?.message);
    } else {
        toastr.error('Error', response?.message);
    }

    // return response?.data?.isDeleted == true ? true : false;
};

/* #region custom functions */
export const setDeleteRecordId = (id) => (dispatch) => {
    dispatch(slice.actions.setDeleteRecordId({ deleteRecId: id ? id : null }));
}

export const resetEntireForm = () => (dispatch) => {
    // dispatch(slice.actions.getLeadSingleData({ selectedLeadObject: null }))
}

/* #endregion custom functions */

export default slice;
