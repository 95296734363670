import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store'
import { AXIOS_GET, AXIOS_PATCH, AXIOS_POST } from './service/apiService';
import {
  Users as UsersIcon
} from 'react-feather';

interface navItems {
  navList: any,
  navListItems: any,
  defaultListItems: any,

  defaultListItemsNav: any,
  dynamicListItemsNav: any,
}

const initialState: navItems = {
  navList: [],
  navListItems: [],
  defaultListItems: [],

  defaultListItemsNav: [],
  dynamicListItemsNav: [],
}


const slice = createSlice({
  name: 'NavItems',
  initialState,
  reducers: {
    getModuleData(state: navItems, action: PayloadAction<{ list: any }>) {
      let list: any[] = [];

      state.navListItems = action.payload.list;

      for (const [i, items] of action.payload.list.entries()) {
        let item: any;

        if (items.type == 'Iframe') {
          item = {
            title: items.moduleName,
            icon: UsersIcon,
            href: '/app/moduleframe/' + items.id
          }
        }
        else {
          item = {
            title: items.moduleName,
            icon: UsersIcon,
            href: '/app/custommodule/list/' + items.id
          }
        }

        list.push(item)
      }

      state.navList = list
    },
    getDefaultListData(state: navItems, action: PayloadAction<{ list: any }>) {
      state.defaultListItems = action.payload.list
    },
    getNavListItems(state: navItems, action: PayloadAction<{ dynamic: any, default: any }>) {
      state.defaultListItemsNav = action.payload.default

      let list: any[] = [];

      for (const [i, items] of action.payload.dynamic.entries()) {
        let item: any;

        if (items.type == 'Iframe') {
          item = {
            title: items.moduleName,
            icon: UsersIcon,
            href: '/app/moduleframe/' + items.id
          }
        } else {
          item = {
            title: items.moduleName,
            icon: UsersIcon,
            href: '/app/custommodule/list/' + items.id
          }
        }

        list.push(item);
      }

      state.dynamicListItemsNav = list
    }
  }
})

export const reducer = slice.reducer;

export const getnavList = (): AppThunk => async (dispatch) => {
  const response: any = await AXIOS_GET('/api/project-module/meta/1', {}, {});
  if (response?.status) {
    dispatch(slice.actions.getModuleData({ list: response.data }));
  }
};

export const getDefaultList = (): AppThunk => async (dispatch) => {
  const response: any = await AXIOS_GET('/api/project-module/default-module/get-default-module', {}, {});
  dispatch(slice.actions.getDefaultListData({ list: response?.data || [] }));
};

export const getpermission = (): AppThunk => async (dispatch) => {
  const response: any = await AXIOS_GET('/api/v1/auth/me/permission', {}, {});
  dispatch(slice.actions.getNavListItems({ dynamic: response.dynamic_module, default: response.default_modules }));
};

export default slice;