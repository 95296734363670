import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store'
import { AXIOS_DELETE, AXIOS_GET, AXIOS_PATCH, AXIOS_POST, BASE_URL } from './service/apiService';
import { toastr } from 'react-redux-toastr';
import { KnowledgeBaseStateData } from 'src/types/knowledgeBase';

const initialState: KnowledgeBaseStateData = {
    knowledgeBaseList: [],
    categoryDropdownList: [],
    page: 1,
    limit: 5,
    totalCount: 0,
    selectedKnowledgeBaseObject: null,
    loader: false,
    deleteRecId: null,
    previewData: null
};

const slice = createSlice({
    name: 'knowledgeBase',
    initialState,
    reducers: {
        setknowledgeBaseList(state: KnowledgeBaseStateData, action: PayloadAction<{ knowledgeBaseList: any[], totalCount: number }>) {

            state.knowledgeBaseList = action.payload.knowledgeBaseList;
            state.totalCount = action.payload.totalCount;
        },
        setKnowledgebaseSingleData(state: KnowledgeBaseStateData, action: PayloadAction<{ selectedKnowledgeBaseObject: any }>) {
            state.selectedKnowledgeBaseObject = action.payload.selectedKnowledgeBaseObject
        },
        loader(state: KnowledgeBaseStateData, action: PayloadAction<any>) {
            state.loader = action.payload.loader
        },
        setCategoryDropdownList(state: KnowledgeBaseStateData, action: PayloadAction<{ knowledgeBaseDropdownList: any[] }>) {
            state.categoryDropdownList = action.payload.knowledgeBaseDropdownList
        },
        setDeleteRecordId(state: KnowledgeBaseStateData, action: PayloadAction<{ deleteRecId: string | number }>) {
            state.deleteRecId = action.payload.deleteRecId
        },
        setPreviewData(state: KnowledgeBaseStateData, action: PayloadAction<{ previewData: any }>) {
            state.previewData = action.payload.previewData
        }
    }
});

export const reducer = slice.reducer;

export const getAllKnowledgeBase = (page: number = 0, limit: number = 5, searchKey: string = ''): AppThunk => async (dispatch) => {
    dispatch(slice.actions.loader({ loader: true }));

    const response: any = await AXIOS_GET(`/api/knowledgebase?page=${page + 1}&limit=${limit}&searchKey=${searchKey}`, {}, {});

    if (response) {
        dispatch(slice.actions.setknowledgeBaseList({ knowledgeBaseList: response?.data || [], totalCount: response?.totalCount || 0 }))
    }

    dispatch(slice.actions.loader({ loader: false }));
};

export const createKnowledgeBase = (payload, history): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_POST('/api/knowledgebase', payload, {});

    if (response?.data?.id) {
        toastr.success('Success', response?.message);
        history.replace(`/app/knowledgebase/create-knowledgebase/create-kb/${response?.data?.id}`);
        dispatch(slice.actions.setKnowledgebaseSingleData({ selectedKnowledgeBaseObject: response?.data }))
    } else {
        toastr.error('Error', response?.message);
    }
};

export const updateKnowledgeBase = (id, payload): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_PATCH(`/api/knowledgebase/${id}`, payload, {});

    if (response?.data?.id) {
        toastr.success('Success', response?.message);
        dispatch(slice.actions.setKnowledgebaseSingleData({ selectedKnowledgeBaseObject: response?.data }))
    } else {
        toastr.error('Error', response?.message);
    }
};

export const getKnowledgebaseById = (id): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_GET('/api/knowledgebase/' + id, {}, {});

    if (response?.status == true) {
        dispatch(slice.actions.setKnowledgebaseSingleData({ selectedKnowledgeBaseObject: response?.data || null }))
    }
};

export const deleteKnowledgebaseById = (knowledgeBaseList, id): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_DELETE('/api/knowledgebase/' + id, {});
    dispatch(slice.actions.setDeleteRecordId({ deleteRecId: null }));

    if (response?.data?.isDeleted) {
        toastr.success('Success', response?.message);
    } else {
        toastr.error('Error', response?.message);
    }

    return response?.data?.isDeleted == true ? true : false;

};

export const getCategoryDropdownList = (skip: number = 0, limit: number = 10, searchKey: string = ''): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_GET(`/api/knowledgebase/category?page=${skip}&limit=${limit}&searchKey=${searchKey}`, {}, {});

    if (response) {
        dispatch(slice.actions.setCategoryDropdownList({ knowledgeBaseDropdownList: response?.data || [] }));
    }
};

export const createCategory = (payload): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_POST('/api/knowledgebase/category', payload, {});

    if (response?.data?.id) {
        toastr.success('Success', response?.message);
    } else {
        toastr.error('Error', response?.message);
    }

    return response?.data?.id ? true : false;
};

export const getKnowledgebasePreviewById = (id): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_GET(`/api/knowledgebase/${id}/preview`, {}, {});

    if (response?.status == true) {
        dispatch(slice.actions.setPreviewData({ previewData: response?.data?.htmlContent || null }))
    } else {
        toastr.error('Error', response?.message);
    }
};

/* #region custom functions */
export const setDeleteRecordId = (id) => (dispatch) => {
    dispatch(slice.actions.setDeleteRecordId({ deleteRecId: id ? id : null }));
}

export const resetEntireForm = () => (dispatch) => {
    dispatch(slice.actions.setKnowledgebaseSingleData({ selectedKnowledgeBaseObject: null }))
}
/* #endregion custom functions */

export default slice;
