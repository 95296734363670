import React from 'react';
import type { FC, ReactNode } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';

interface GuestGuardProps {
  children?: ReactNode;
}

const getToken = () => {
  const token = localStorage.getItem('accessToken')
  console.log("Access Token", token)
  return token
}

const GuestGuard: FC<GuestGuardProps> = ({ children }) => {
  const token = getToken()

  if (token) {
    return <Redirect to="/app/dashboard" />;
  }

  return (
    <>
      {children}
    </>
  );
};

GuestGuard.propTypes = {
  children: PropTypes.node
};

export default GuestGuard;
