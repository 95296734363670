import React from 'react';
import type { FC } from 'react';
import { Avatar, Typography } from '@material-ui/core';

interface LogoProps {
  [key: string]: any;
}

const Logo: FC<LogoProps> = (props) => {
  return (
    <div>
      <img src='/static/KgInvicta.png' alt='Kgisl' style={{height: '6vh'}}></img>

      {/* <Typography variant="h6" component="h6">
        Desk.
      </Typography> */}

    </div>
    // <img
    //   alt="Logo"
    //   src="/static/logo.svg"
    //   {...props}
    // />
  );
}

export default Logo;
