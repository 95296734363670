import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store'
import axios from 'axios';
import { AXIOS_GET, AXIOS_PATCH, AXIOS_POST, AXIOS_DELETE } from './service/apiService';
import StateManagementView from 'src/views/docs/StateManagementView';


interface AdminModule {
    moduleList: any[],
    page: number,
    limit: number,
    totalCount: number,
    formConfig: any,
    loader:boolean
}


const initialState: AdminModule = {
    moduleList: [],
    page: 1,
    limit: 10,
    totalCount: 0,
    formConfig: null,
    loader:false
};

const initialFormValueState = null


const slice = createSlice({
    name: 'DynamicModule',
    initialState,
    reducers: {
        getListData(state: AdminModule, action: PayloadAction<{ jobData: any[], totalCount: number }>) {
            state.moduleList = action.payload.jobData;
            state.totalCount = action.payload.totalCount;
        },

        getModuleDetails(state: AdminModule, action: PayloadAction<{ formConfig: any }>) {
            console.log(action.payload.formConfig)
            state.formConfig = action.payload.formConfig;
        },
        loader(state: AdminModule, action:PayloadAction<any>){
            state.loader = action.payload.loader
          },
        updateFrom(state: AdminModule){
            state.formConfig = initialFormValueState;
        },
        updateModuleList(state: AdminModule,action: PayloadAction<{id:any, list:any[]}>){
            let filteredArray = action.payload.list.filter(item => item.id != action.payload.id)
            state.moduleList = filteredArray
          }
    }
})

export const reducer = slice.reducer;


export const getList = ( page: number = 1, limit: number = 10, searchKey: string = ''): AppThunk => async (dispatch) => {
    console.log("Dynamic Page Module List")
    dispatch(slice.actions.loader({loader:true}))
    const response: any = await AXIOS_GET(`/api/admin-module?page=${page}&limit=${limit}&searchKey=${searchKey}`, {}, {});
    if (response) {
        console.log(response)
        dispatch(slice.actions.getListData({ jobData: response.data, totalCount: response.data?.totalCount || 0 }));
    }
    dispatch(slice.actions.loader({loader:false}))
};

export const getModuleById = (id): AppThunk => async (dispatch) => {
    console.log("get getProjectModule api call")
    dispatch(slice.actions.loader({loader:true}))
    const response: any = await AXIOS_GET('/api/admin-module/' + id, {}, {});
    if (response) {
        console.log(response)
        dispatch(slice.actions.getModuleDetails({formConfig: response.data}));
    } else {

    }
    dispatch(slice.actions.loader({loader:false}))
  };

export const saveModule = (payload, history): AppThunk => async (dispatch) => {
    console.log(payload)
    const response: any = await AXIOS_POST('/api/admin-module', payload, {});
    console.log("responses", response)
    if (response) {
       history.push(`/app/adminmodule`)
    } else {
  
    }
  };


  export const updateModule = (id, payload, history): AppThunk => async (dispatch) => {
    try {
      dispatch(slice.actions.loader({loader:true}));

      const response: any = await AXIOS_PATCH('/api/admin-module/' + id, payload, {});
      if (response) {
        history.push(`/app/adminmodule`);
      }
    } catch(err) {

    } finally {
      dispatch(slice.actions.loader({loader:false}));
    }
  };

  export const deleteModuleById = (id, datalist) : AppThunk => async (dispatch) => {
    console.log('ID-S',id)
    const response: any = await AXIOS_DELETE('/api/admin-module/' + id, {});
    console.log("responses", response)
    if(response.data.isDeleted){
      dispatch(slice.actions.updateModuleList({ id :  id, list : datalist}));
    }
  };

  export const formvaluerefresh = () : AppThunk => async (dispatch) => { 
    dispatch(slice.actions.updateFrom());
  };
  

export default slice;