import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store'
import { AXIOS_DELETE, AXIOS_GET, AXIOS_PATCH, AXIOS_POST, BASE_URL } from './service/apiService';
import { toastr } from 'react-redux-toastr';
import { LeadStateData } from 'src/types/leads';

const initialState: LeadStateData = {
    leadList: [],
    statusDropdownList: [],
    page: 1,
    limit: 5,
    totalCount: 0,
    selectedLeadObject: null,
    loader: false,
    deleteRecId: null
};

const slice = createSlice({
    name: 'leads',
    initialState,
    reducers: {
        getLeadList(state: LeadStateData, action: PayloadAction<{ leadList: any[], totalCount: number }>) {
            state.leadList = action.payload.leadList;
            state.totalCount = action.payload.totalCount;
        },
        getLeadSingleData(state: LeadStateData, action: PayloadAction<{ selectedLeadObject: any }>) {
            state.selectedLeadObject = action.payload.selectedLeadObject
        },
        loader(state: LeadStateData, action: PayloadAction<any>) {
            state.loader = action.payload.loader
        },
        getStatusDropdownList(state: LeadStateData, action: PayloadAction<{ statusDropdownList: any[] }>) {
            state.statusDropdownList = action.payload.statusDropdownList
        },
        setDeleteRecordId(state: LeadStateData, action: PayloadAction<{ deleteRecId: string | number }>) {
            state.deleteRecId = action.payload.deleteRecId
        }
    }
});

export const reducer = slice.reducer;

export const getAllLeads = (page: number = 0, limit: number = 5, searchKey: string = ''): AppThunk => async (dispatch) => {
    dispatch(slice.actions.loader({ loader: true }));

    const response: any = await AXIOS_GET(`/api/lead?page=${page + 1}&limit=${limit}&searchKey=${searchKey}`, {}, {});

    if (response) {
        dispatch(slice.actions.getLeadList({ leadList: response?.data || [], totalCount: response?.totalCount || 0 }))
    }

    dispatch(slice.actions.loader({ loader: false }));
};

export const saveLead = (payload, history): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_POST('/api/lead', payload, {});

    if (response?.data?.id) {
        toastr.success('Success', response?.message);
        history.replace(`/app/lead/create-lead/${response?.data?.id}`);
        dispatch(slice.actions.getLeadSingleData({ selectedLeadObject: response?.data }))
    } else {
        toastr.error('Error', response?.message);
    }
};

export const updateLead = (id, payload): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_PATCH(`/api/lead/${id}`, payload, {});

    if (response?.data?.id) {
        toastr.success('Success', response?.message);
        dispatch(slice.actions.getLeadSingleData({ selectedLeadObject: response?.data }))
    } else {
        toastr.error('Error', response?.message);
    }
};

export const getLeadById = (id): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_GET('/api/lead/' + id, {}, {});

    if (response?.status == true) {
        dispatch(slice.actions.getLeadSingleData({ selectedLeadObject: response?.data || null }))
    }
};

export const deleteLeadById = (leadList, id): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_DELETE('/api/lead/' + id, {});
    dispatch(slice.actions.setDeleteRecordId({ deleteRecId: null }));

    if (response?.data?.isDeleted) {
        toastr.success('Success', response?.message);
    } else {
        toastr.error('Error', response?.message);
    }

    return response?.data?.isDeleted == true ? true : false;

};

export const getStatusDropdownList = (): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_GET('/api/role', {}, {});

    if (response) {
        dispatch(slice.actions.getStatusDropdownList({ statusDropdownList: response?.data || [] }));
    }
};

/* #region custom functions */
export const setDeleteRecordId = (id) => (dispatch) => {
    dispatch(slice.actions.setDeleteRecordId({ deleteRecId: id ? id : null }));
}

export const resetEntireForm = () => (dispatch) => {
    dispatch(slice.actions.getLeadSingleData({ selectedLeadObject: null }))
}
/* #endregion custom functions */

export default slice;
