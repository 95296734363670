import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk, RootState } from 'src/store'
import type { ProjectsStateData } from 'src/types/projects';
import { AXIOS_DELETE, AXIOS_GET, AXIOS_PATCH, AXIOS_POST } from './service/apiService';
import { toastr } from 'react-redux-toastr';

const initialState: ProjectsStateData = {
    projectList: [],
    page: 1,
    limit: 5,
    totalCount: 0,
    selectedProjectObject: null,
    loader: false,
    cloneLoader: false,
    deleteRecId: null,
    publicProjectList: [],
    publicProjectTotalCount: 0
};

const slice = createSlice({
    name: 'projects',
    initialState,
    reducers: {
        getProjectsList(state: ProjectsStateData, action: PayloadAction<{ projectList: any[], totalCount: number }>) {
            state.projectList = action.payload.projectList;
            state.totalCount = action.payload.totalCount;
        },
        getProjectSingleData(state: ProjectsStateData, action: PayloadAction<{ selectedProjectObject: any }>) {
            state.selectedProjectObject = action.payload.selectedProjectObject
        },
        loader(state: ProjectsStateData, action: PayloadAction<any>) {
            state.loader = action.payload.loader
        },
        setDeleteRecordId(state: ProjectsStateData, action: PayloadAction<{ deleteRecId: string | number }>) {
            state.deleteRecId = action.payload.deleteRecId
        },
        deleteListRecordFromStateById(state: ProjectsStateData, action: PayloadAction<{ projectList: any[], deleteRecId: string | number }>) {
            let tempList: any[] = action.payload.projectList || [];
            let finalPayloadList: any[] = tempList.length ? tempList.filter(item => item?.id != action.payload.deleteRecId) : [...tempList];
            state.projectList = finalPayloadList;
            state.totalCount = state.totalCount - 1;
        },
        setPublicProjectList(state: ProjectsStateData, action: PayloadAction<{ publicProjectList: any[], publicProjectTotalCount: number }>) {
            state.publicProjectList = [...state.publicProjectList, ...action.payload.publicProjectList];
            state.publicProjectTotalCount = action.payload.publicProjectTotalCount;
        },
        cloneLoader(state: ProjectsStateData, action: PayloadAction<any>) {
            state.cloneLoader = action.payload.cloneLoader
        },
        clearPublicProjectStateValue(state: ProjectsStateData, action: PayloadAction<{ publicProjectList: any[], publicProjectTotalCount: number }>) {
            state.publicProjectList = [];
            state.publicProjectTotalCount = 0;
        }
    }
});

export const reducer = slice.reducer;

export const getAllProjects = (page: number = 0, limit: number = 10, searchKey: string = ''): AppThunk => async (dispatch) => {
    dispatch(slice.actions.loader({ loader: true }));
    let pageV = page * limit
    const response: any = await AXIOS_GET(`/api/projects?page=${page + 1}&limit=${limit}&searchKey=${searchKey}`, {}, {});

    if (response) {
        dispatch(slice.actions.getProjectsList({ projectList: response?.data || [], totalCount: response?.totalCount || 0 }))
    }

    dispatch(slice.actions.loader({ loader: false }));
};

export const saveProject = (payload, history): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_POST('/api/projects', payload, {});

    if (response?.data) {
        history.replace(`/app/project/new-project/${response?.data.id}`);
        dispatch(slice.actions.getProjectSingleData({ selectedProjectObject: response?.data }));
        toastr.success('Success', response?.message);
    } else {
        toastr.error('Error', response?.message);
    }
};

export const updateProject = (id, payload): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_PATCH(`/api/projects/${id}`, payload, {});

    if (response?.data?.id) {
        dispatch(slice.actions.getProjectSingleData({ selectedProjectObject: response?.data }));
        toastr.success('Success', response?.message);
    } else {
        toastr.error('Error', response?.message);
    }
};

export const getProjectById = (id): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_GET('/api/projects/' + id, {}, {});

    if (response) {
        dispatch(slice.actions.getProjectSingleData({ selectedProjectObject: response?.data }))
    }
};

export const deleteProjectById = (projectlist, id): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_DELETE('/api/projects/' + id, {});
    dispatch(slice.actions.setDeleteRecordId({ deleteRecId: null }));

    if (response?.data?.isDeleted) {
        toastr.success('Success', response?.message);
        // dispatch(slice.actions.deleteListRecordFromStateById({projectList: projectlist, deleteRecId: id}));
    } else {
        toastr.error('Error', response?.message);
    }

    return response?.data?.isDeleted || false;
};

export const getPublicProjectsList = (skip: number = 0, limit: number = 5): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_GET(`/api/projects/public?skip=${skip}&limit=${limit}`, {}, {});

    if (response?.status == true) {
        dispatch(slice.actions.setPublicProjectList({ publicProjectList: response?.data || [], publicProjectTotalCount: response?.totalCount || 0 }));
    } else {
        toastr.error('Error', response?.message);
    }

    return response?.data && response?.data.length ? true : false;
};

export const clonePublicProject = (payload): AppThunk => async (dispatch) => {
    try {
        dispatch(slice.actions.cloneLoader({ cloneLoader: true }));
        const response: any = await AXIOS_POST('/api/projects/clone', payload, {});

        if (response?.data?.id) {
            toastr.success('Success', response?.message);
        } else {
            toastr.error('Error', response?.message);
        }

        return response?.data?.id ? true : false;

    } catch (err) {
        toastr.error('Error', err);
        return false;
    } finally {
        dispatch(slice.actions.cloneLoader({ cloneLoader: false }));
    }
};


/* #region custom functions */
export const setDeleteRecordId = (id) => (dispatch) => {
    dispatch(slice.actions.setDeleteRecordId({ deleteRecId: id ? id : null }));
}

export const resetEntireForm = () => (dispatch) => {
    dispatch(slice.actions.getProjectSingleData({ selectedProjectObject: null }));
}

export const resetPublicProjectData = () => (dispatch) => {
    dispatch(slice.actions.clearPublicProjectStateValue({ publicProjectList: [], publicProjectTotalCount: 0 }));

    
}
/* #endregion custom functions */

export default slice;
