import { createSlice, current } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import type { AppThunk } from 'src/store'
import axios from 'src/utils/axios';
import type { Event } from 'src/types/calendar';
import { AXIOS_DELETE, AXIOS_GET, AXIOS_PATCH, AXIOS_POST } from './service/apiService';
import { toastr } from 'react-redux-toastr';
import moment from 'moment';
import { any } from 'prop-types';

interface CalendarState {
  events: Event[],
  selectedAppointmentObject: any,
  isModalOpen: boolean;
  selectedEventId: string | null;
  selectedRange: {
    start: number;
    end: number;
  } | null;
  userObject: {
    userDropdownList: any[],
    totalCount: number
  }
  contactObject: {
    contactDropdownList: any[],
    totalCount: number
  },
  loader: boolean,
  deleteRecId: number | string
}

const initialState: CalendarState = {
  events: [],
  selectedAppointmentObject: any,
  isModalOpen: false,
  selectedEventId: null,
  selectedRange: null,
  userObject: {
    userDropdownList: [],
    totalCount: 0
  },
  contactObject: {
    contactDropdownList: [],
    totalCount: 0
  },
  loader: false,
  deleteRecId: null
};

const slice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    selectEvent(state: CalendarState, action: PayloadAction<{ eventId?: string; }>) {
      const { eventId = null } = action.payload;

      state.isModalOpen = true;
      state.selectedEventId = eventId;
    
    },
    selectRange(state: CalendarState, action: PayloadAction<{ start: number; end: number; }>) {
      const { start, end } = action.payload;

      state.isModalOpen = true;
      state.selectedRange = {
        start,
        end
      };
    },
    setAppointments(state: CalendarState, action: PayloadAction<{ events: Event[]; }>) {
      let { events } = action.payload;
      let formatedAppointmentList: any[] = [];

      if (events && events.length) {
        formatedAppointmentList.push(...events.map(obj => ({ ...fnResponsePayloadBinding(obj) })));
        state.events = [...formatedAppointmentList];
      } else {
        state.events = [];
      }
    },
    setAppointmentSingleData(state: CalendarState, action: PayloadAction<{ selectedAppointmentObject: any }>) {
      let appointmentObj: any = action.payload.selectedAppointmentObject;

      let formattedAppointmentObject: any = {
        id: appointmentObj?.id,
        title: appointmentObj?.title || '',
        description: appointmentObj?.description || '',
        user: formatDropdownList(appointmentObj?.user, 'id', 'firstName') || [],
        contact: appointmentObj?.contact || [],
        start: appointmentObj?.startDate || moment().toDate(),
        end: appointmentObj?.endDate || moment().add(30, 'minutes').toDate(),
        allDay: appointmentObj?.allDay == true ? true : false,
        color: '',
      }

      state.isModalOpen = true;
      state.selectedAppointmentObject = {...formattedAppointmentObject};
    },
    setUserDropdownList(state: CalendarState, action: PayloadAction<{ userDropdownList: any[], totalCount: number }>) {
      let tempUserDropdownList: any[] = action.payload.userDropdownList;
      let finalUserDropdownList: any[] = formatDropdownList(tempUserDropdownList, 'id', 'firstName');
      state.userObject.userDropdownList = [...finalUserDropdownList];
      state.userObject.totalCount = action.payload.totalCount;
    },
    setContactDropdownList(state: CalendarState, action: PayloadAction<{ contactDropdownList: any[], totalCount: number }>) {
      state.contactObject.contactDropdownList = action.payload.contactDropdownList;
      state.contactObject.totalCount = action.payload.totalCount;
    },
    setDeleteRecordId(state: CalendarState, action: PayloadAction<{ deleteRecId: string | number }>) {
      state.deleteRecId = action.payload.deleteRecId
    },
    loader(state: CalendarState, action: PayloadAction<any>) {
      state.loader = action.payload.loader
    },
    openModal(state: CalendarState) {
      state.isModalOpen = true;
    },
    closeModal(state: CalendarState) {
      state.isModalOpen = false;
      state.selectedEventId = null;
      state.selectedRange = null;
    },
  }
});

const fnResponsePayloadBinding = (response: any) => {
  return {
    'id': response?.id || '',
    'title': response?.title || '',
    'description': response?.description || '',
    'color': response?.color || '',
    'allDay': response?.allDay == true ? true : false,
    'start': (response?.startDate) || '',
    'end': (response?.endDate) || '',
  }
}

const formatDropdownList = (sortedOutMasterList: any[] = [], keyPair: string | number, valuePair: string = '') => {
  let actualDropdownList: any[] = [];
  
  if (sortedOutMasterList && sortedOutMasterList.length) {
    sortedOutMasterList.forEach(element => {
      actualDropdownList.push({ id: element[keyPair], title: element[valuePair] });
    });

    return actualDropdownList;
  } else {
      return actualDropdownList;
  }
}

export const reducer = slice.reducer;

export const selectEvent = (eventId?: string): AppThunk => async (dispatch) => {
  dispatch(slice.actions.selectEvent({ eventId }));
};

export const selectRange = (start: Date, end: Date): AppThunk => (dispatch) => {
  dispatch(slice.actions.selectRange({
    start: start.getTime(),
    end: end.getTime()
  }));
};

export const getAllAppointments = (startDate: string = '', endDate: string = ''): AppThunk => async (dispatch) => {
  dispatch(slice.actions.loader({ loader: true }));

  const response: any = await AXIOS_GET(`/api/appointment?startDate=${startDate}&endDate=${endDate}`, {}, {});

  dispatch(slice.actions.setAppointments({ events: response || [] }))

  dispatch(slice.actions.loader({ loader: false }));
};

export const createAppointment = (payload: any): AppThunk => async (dispatch) => {
  const response: any = await AXIOS_POST('/api/appointment', payload, {});

  if (response?.data?.id) {
    toastr.success('Success', response?.message);
  } else {
    toastr.error('Error', response?.message);
  }

  return response?.data?.id ? true : false;
};

export const updateAppointment = (id, payload): AppThunk => async (dispatch) => {
  const response: any = await AXIOS_PATCH(`/api/appointment/${id}`, payload, {});

  if (response?.data?.id) {
      toastr.success('Success', response?.message);
  } else {
      toastr.error('Error', response?.message);
  }

  return response?.data?.id ? true : false;

};

export const deleteAppointmentById = (id): AppThunk => async (dispatch) => {
  const response: any = await AXIOS_DELETE('/api/appointment/' + id, {});
  dispatch(slice.actions.setDeleteRecordId({ deleteRecId: null }));

  if (response?.data?.isDeleted) {
      toastr.success('Success', response?.message);
  } else {
      toastr.error('Error', response?.message);
  }

  return response?.data?.isDeleted == true ? true : false;
};

export const getAppointmentById = (id): AppThunk => async (dispatch) => {
  const response: any = await AXIOS_GET('/api/appointment/' + id, {}, {});

  dispatch(slice.actions.setAppointmentSingleData({ selectedAppointmentObject: response?.data || null }))
};

export const getUserDropdownList = (skip: number = 0, limit: number = 10, searchKey: string = ''): AppThunk => async (dispatch) => {
  const response: any = await AXIOS_GET(`/api/users/list?skip=${skip}&limit=${limit}&searchKey=${searchKey}`, {}, {});

  dispatch(slice.actions.setUserDropdownList({ userDropdownList: response?.data || [], totalCount: response?.totalCount || 0 }));
};

export const getContactDropdownList = (skip: number = 0, limit: number = 10, searchKey: string = ''): AppThunk => async (dispatch) => {
  const response: any = await AXIOS_GET(`/api/contact/list?skip=${skip}&limit=${limit}&searchKey=${searchKey}`, {}, {});

  dispatch(slice.actions.setContactDropdownList({ contactDropdownList: response?.data || [], totalCount: response?.totalCount || 0 }));
};

/* #region custom functions */
export const setDeleteRecordId = (id) => (dispatch) => {
  dispatch(slice.actions.setDeleteRecordId({ deleteRecId: id ? id : null }));
}

export const openModal = (): AppThunk => (dispatch) => {
  dispatch(slice.actions.setAppointmentSingleData({ selectedAppointmentObject: null }))

};

export const closeModal = (): AppThunk => (dispatch) => {
  dispatch(slice.actions.closeModal());
};

// export const resetEntireForm = () => (dispatch) => {
//   dispatch(slice.actions.setAppointmentSingleData({ selectedAppointmentObject: null }))
// }
/* #endregion custom functions */

export default slice;
