import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store'
import { AXIOS_DELETE, AXIOS_GET, AXIOS_PATCH, AXIOS_POST, BASE_URL } from './service/apiService';
import { toastr } from 'react-redux-toastr';
import { ContactStateData } from 'src/types/contacts';

const initialState: ContactStateData = {
    contactList: [],
    stateDropdownList: [],
    page: 1,
    limit: 5,
    totalCount: 0,
    selectedContactObject: null,
    loader: false,
    deleteRecId: null
};

const slice = createSlice({
    name: 'contacts',
    initialState,
    reducers: {
        getContactList(state: ContactStateData, action: PayloadAction<{ contactList: any[], totalCount: number }>) {
            state.contactList = action.payload.contactList;
            state.totalCount = action.payload.totalCount;
        },
        getContactSingleData(state: ContactStateData, action: PayloadAction<{ selectedContactObject: any }>) {
            state.selectedContactObject = action.payload.selectedContactObject
        },
        loader(state: ContactStateData, action: PayloadAction<any>) {
            state.loader = action.payload.loader
        },
        getStatusDropdownList(state: ContactStateData, action: PayloadAction<{ stateDropdownList: any[] }>) {
            state.stateDropdownList = action.payload.stateDropdownList
        },
        setDeleteRecordId(state: ContactStateData, action: PayloadAction<{ deleteRecId: string | number }>) {
            state.deleteRecId = action.payload.deleteRecId
        }
    }
});

export const reducer = slice.reducer;

export const getAllContacts = (page: number = 0, limit: number = 5, searchKey: string = ''): AppThunk => async (dispatch) => {
    dispatch(slice.actions.loader({ loader: true }));

    const response: any = await AXIOS_GET(`/api/contact?page=${page + 1}&limit=${limit}&searchKey=${searchKey}`, {}, {});

    if (response) {
        dispatch(slice.actions.getContactList({ contactList: response?.data || [], totalCount: response?.totalCount || 0 }))
    }

    dispatch(slice.actions.loader({ loader: false }));
};

export const saveContact = (payload, history): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_POST('/api/contact', payload, {});

    if (response?.data?.id) {
        toastr.success('Success', response?.message);
        history.replace(`/app/contact/create-contact/${response?.data?.id}`);
        dispatch(slice.actions.getContactSingleData({ selectedContactObject: response?.data }))
    } else {
        toastr.error('Error', response?.message);
    }
};

export const updateContact = (id, payload): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_PATCH(`/api/contact/${id}`, payload, {});

    if (response?.data?.id) {
        toastr.success('Success', response?.message);
        dispatch(slice.actions.getContactSingleData({ selectedContactObject: response?.data }))
    } else {
        toastr.error('Error', response?.message);
    }
};

export const getContactById = (id): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_GET('/api/contact/' + id, {}, {});

    if (response?.status == true) {
        dispatch(slice.actions.getContactSingleData({ selectedContactObject: response?.data || null }))
    }
};

export const deleteContactById = (contactList, id): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_DELETE('/api/contact/' + id, {});
    dispatch(slice.actions.setDeleteRecordId({ deleteRecId: null }));

    if (response?.data?.isDeleted) {
        toastr.success('Success', response?.message);
    } else {
        toastr.error('Error', response?.message);
    }

    return response?.data?.isDeleted == true ? true : false;

};

export const getStatusDropdownList = (): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_GET('/api/role', {}, {});

    if (response) {
        dispatch(slice.actions.getStatusDropdownList({ stateDropdownList: response?.data || [] }));
    }
};

/* #region custom functions */
export const setDeleteRecordId = (id) => (dispatch) => {
    dispatch(slice.actions.setDeleteRecordId({ deleteRecId: id ? id : null }));
}

export const resetEntireForm = () => (dispatch) => {
    dispatch(slice.actions.getContactSingleData({ selectedContactObject: null }))
}
/* #endregion custom functions */

export default slice;
