import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store'
import axios from 'axios';
import { AXIOS_GET, AXIOS_PATCH, AXIOS_POST } from './service/apiService';
import StateManagementView from 'src/views/docs/StateManagementView';

import useAuth from 'src/hooks/useAuth';

interface auth {
    user: any,
    backgroudUrl: string,
    projectName: string
}

const initialState: auth = {
    user: null,
    backgroudUrl: null,
    projectName: null
}

const slice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        me(state: auth, action: PayloadAction<{ user: any }>) {
            state.user = action.payload.user;
        },
        setLoginBackground(state: auth, action: PayloadAction<{ backgroudUrl: string, projectName: string }>) {
            state.backgroudUrl = action.payload.backgroudUrl;
            state.projectName = action.payload.projectName;
        }
    },
})

export const reducer = slice.reducer;

export const loginMethod = (payload): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_POST('/api/v1/auth/email/login', payload, {});
    //    login(payload.email, payload.password)
    console.log('Slicer', response)
};

export const me = (): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_GET('/api/v1/auth/me', {}, {});
    console.log("response", me)
    dispatch(slice.actions.me({ user: response }))

}

export const getLoginBackground = (id): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_GET('/api/projects/getBG/' + id, {}, {});

    dispatch(slice.actions.setLoginBackground({ 
        backgroudUrl: response?.data?.backgroundUrl || null,
        projectName: response?.data?.projectName || null 
    }))
};

export default slice;